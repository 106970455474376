<template>
<div class="dashboard">
<header class='page_header'>
  <div class='row'>
    <div class='columns shrink'>
    <h2 class='logo'><img title="Diversifieds" :src="logo" /></h2>
    </div>
    <div class='columns small-12 medium-6'>
      <form class="simple_form new_search" id="new_search" autocomplete="off" novalidate="novalidate" action="/" accept-charset="UTF-8" method="get">
        <div class='row align-middle'>
          <div class='columns'>
            <div class="input search optional search_q q"><input v-model="term" name="q" class="string search optional" autofocus="autofocus" placeholder="I&#39;m Searching For" type="search" /></div>
          </div>
          <div class='columns'>
            <div class="input search optional search_l l"><input v-model="location" name="l" class="string search optional" placeholder="City &amp; State or Zip" type="search" /></div>
          </div>
          <div class='columns shrink'>
            <button @click.prevent="runSearch" value="Search" class="button">Search</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</header>
<div class="banner_ad top">
  <div v-if="checkedMobile && !mobile"><Butler kind="banner" :index="100" /></div>
  <div v-if="checkedMobile && mobile"><Butler kind="mobile" :index="101" /></div>
</div>
<div class="listing_wrap">
  <div class="list_column">
    <ul class="blocks">
      <template v-for="(ad, index) in advertisements">
        <li class="sidebar_ad"
              v-if="(index % 3 == 0) && index != 0"
              :key="`ad-${index}`">
          <Butler kind="sidebar" :index="index / 3"/>
        </li>
        <AdItem :ad="ad" :publisherId="publisherId" :key="ad.id" @select-ad="selectAd" :is-current="currentAd && ad.id == currentAd.id" />
      </template>

      <div class="interaction_loader"></div>
      <li class="loader" v-if="loading"><BarLoader :loading="loading" color="#f7bd15" :width="100" widthUnit="%" :height="6"  ></BarLoader></li>
      <div v-if="showNoMorePages" class="no_more_ads">Thanks for visiting Diversifieds!</div>
    </ul>
  </div>
  <div class="item_column">

    <div class="item_display" v-if="!mobile">
      <div class="default_text" v-if="!currentAd">
        <img title="Diversifieds" :src="logoBlack" />
        <p>Select a job from the list to see details.</p>
      </div>
      <AdVue v-if="currentAd" :ad="currentAd" />
    </div>

  </div>
</div>


<transition :name="modalTransition" mode="in-out" v-on:after-enter="afterEnterModal" v-on:after-leave="afterLeaveModal">
  <div class="modal_overlay" v-if="mobile && currentAd">
    <div class="overlay_internal">
      <header><a href="#" @click.prevent="clearAd"><font-awesome-icon icon="fa-solid fa-arrow-left" /> Back to listings</a></header>
      <AdVue v-if="currentAd" :ad="currentAd" />
    </div>
  </div>
</transition>

<div class="banner_ad bottom">
  <div class="columns" v-if="!mobile"><Butler kind="banner" :index="102" /></div>
  <div class="columns" v-if="mobile"><Butler kind="mobile" :index="103" /></div>
</div>
</div>
</template>

<script>
import Vue from 'vue/dist/vue.esm'
import adViewMixin from '../modules/ad_view_mixin'
import AdItem from '../modules/ad_listing_item'
import Butler from '../modules/butler'
import AdVue from '../modules/advertisement'
import logo from '../images/logo.svg'
import logoBlack from '../images/logo-black.svg'
import axios from 'axios'
import { BarLoader } from '@saeris/vue-spinners'

export default {
  mixins: [adViewMixin],
  props: ['publisherId', 'initialLocation'],
  components: {AdItem, AdVue, BarLoader, Butler},
  mounted() {
    this.checkMobile()
    this.setUuid()
    this.location = this.initialLocation
    this.saveAdView()
    this.runSearch()

    this.logo = logo
    this.logoBlack = logoBlack
    this.adContainer = document.querySelector(".list_column")

    let self = this
    const intersectionObserver = new IntersectionObserver(entries => {
      if(! self.totalPages || self.totalPages <= self.page || self.loading) return
      if (entries[0].intersectionRatio <= 0 ) return
      self.page++
      self.loading = true
      self.runSearch(false)
    });
    // start observing
    intersectionObserver.observe(document.querySelector(".interaction_loader"));
  },
  beforeUnmount() {

  },
  data() {
    return {
      uuid: null,
      term: '',
      location: '',
      logo: null,
      logoBlack: null,
      advertisements: [],
      currentAd: null,
      totalPages: 1,
      page: 1,
      loading: false,
      checkedMobile: false,
      mobile: false,
      showModal: false,
      modalTransition: 'default'
    }
  },
  computed: {
    modalMode() {
      return this.modalTransition == 'default' ? 'enter' : 'leave'
    },
    showInteraction() {
      return this.totalPages && this.totalPages > this.page
    },
    showNoMorePages() {
      return ! this.loading && this.totalPages && this.totalPages > 3 && this.totalPages == this.page
    }
  },
  watch: {
    term(val) {
      if(! val) {
        this.runSearch()
      }
    },
    location(val) {
      if(! val) {
        this.runSearch()
      }
    },
    currentAd() {
      if(this.mobile && this.currentAd) {
        document.body.classList.add('noscroll')
      }
      else {
        document.body.classList.remove('noscroll')
      }
    }
  },
  methods: {
    runSearch(deleteFirst = true) {
      if(deleteFirst) {
        this.currentAd = null
        this.page = 1
      }
      this.loading = true
      axios({
        method: 'get',
        url:  `/public/items`,
        params: {
          q: this.term,
          l: this.location,
          page: this.page
        }
      }).then((resp) => {
        this.loading = false
        this.totalPages = resp.headers['total-pages'] || 1
        if(deleteFirst) {
          this.page = 1
          this.advertisements = []
        }

        this.advertisements.push(...resp.data)
      })
    },
    selectAd(ad) {
      this.modalTransition = 'default'
      this.currentAd = ad
      // this.modalTransition = 'back'
      this.saveAdView(this.currentAd)
      window.gtag('event', 'page_view', {
        page_title: `${ad.advertiser.name}: ${ad.title}`,
        page_location: `${window.location.origin}/ad/${ad.id}`
      })

    },
    clearAd() {
      this.currentAd = null
    },
    mobileScrollCheck() {
      if(! this.totalPages || this.totalPages <= this.page || this.loading) {
        return
      }
      if(window.scrollY + window.innerHeight + 3 >= document.documentElement.scrollHeight) {
        this.page++
        this.loading = true
        this.runSearch(false)
      }
    },
    scrollCheck() {
      if(! this.totalPages || this.totalPages <= this.page) {
        return
      }

      let scrollY = this.adContainer.scrollHeight - this.adContainer.scrollTop
      let height = this.adContainer.offsetHeight
      let offset = height - scrollY

      if(offset == 0 || (offset <= 5 && offset >-5)) {
        this.page++
        this.loading = true
        this.runSearch(false)
      }
    },
    checkMobile() {
      let mql = window.matchMedia('(min-width: 40em)')
      this.mobile = ! mql.matches
      this.checkedMobile = true
    },
    afterEnterModal() {
      window.scrollTo(0, 0);
    },
    afterLeaveModal() {
      this.modalTransition = 'default'
    }

  },
}
</script>