<template>
  <div class="current_ad" v-if="ad">
    <div class="image" v-if="ad.image"><img :src="ad.image" /></div>
    <div class="logo" v-if="! ad.image && advertiser && advertiser.logo"><img :src="advertiser.logo" /></div>

    <h2>{{ ad.title}}</h2>
    <div class="row align-center">
      <div class="columns">
          <div class="company">{{ advertiser.name}}</div>
          <div class="location">{{ advertiser.city_state }}</div>
      </div>
      <div class="columns shrink"><a class="button apply secondary" :href="ad.url" target="_blank" @click="">Apply Now</a></div>
    </div>

    <div class="description" v-html="ad.description" />

    <div class="item" v-if="ad.job_title">
      <h5>Job Title</h5>
      <ul><li>{{ad.job_title}}</li></ul>
    </div>
    <div class="item" v-if="ad.time_kind">
      <h5>Full/Part time</h5>
      <ul><li>{{ad.time_kind | humanize}}</li></ul>
    </div>
    <div class="item" v-if="ad.remote_kind">
      <h5>Remote or in-person</h5>
      <ul><li>{{ad.remote_kind | humanize}}</li></ul>
    </div>
    <div class="item" v-if="ad.contractor_kind">
      <h5>Employee or contractor</h5>
      <ul><li>{{ad.contractor_kind | humanize}}</li></ul>
    </div>
    <div class="item" v-if="ad.salary_low || ad.salary_high">
      <h5>Salary</h5>
      <ul><li><span v-if="ad.salary_low">{{ad.salary_low}}</span><span v-if="ad.salary_low && ad.salary_high"> — </span><span v-if="ad.salary_high">{{ad.salary_high}} </span> <span v-if="ad.salary_period">{{ ad.salary_period | humanize }}</span></li></ul>
    </div>
    <div class="item" v-if="ad.additional_compensation">
      <h5>Additional Compensation</h5>
      <ul><li>{{ad.additional_compensation}}</li></ul>
    </div>

  </div>
</template>

<script>



export default {
  props: ['ad'],
  mounted() {
    this.advertiser = this.ad.advertiser
  },
  watch: {
    ad(val) {
      this.advertiser = this.ad.advertiser
    },
  },
  data() {
    return {
      advertiser: {}
    }
  },
}

</script>