import Vue from 'vue/dist/vue.esm'
import Rails from '@rails/ujs'

const buttonSelector = '[data-behavior=pay]'

const stripeForm = Vue.component('stripe-form', {
  props: [
    'stripeKey', 'clientSecret',

  ],
  mounted() {
    this.form = this.$refs.paymentform
    this.setupStripe()
  },
  data() {
    return {
      cardErrors: null,
      stripeToken: null,
      disabled: false,
      stripeElements: null,
      stripe: null
    }
  },
  watch: {
    totalCalculated(val) {
      this.setChargeAmount(val)
    }
  },
  methods: {
    setupStripe() {
      this.stripe = window.Stripe(this.stripeKey)
      this.stripeElements = this.stripe.elements({
        clientSecret: this.clientSecret
      })

      const paymentElement = this.stripeElements.create('payment')
      paymentElement.mount('#payment-element')

    },
    submitForm(event) {
      this.cardErrors = null

      event.preventDefault()
      let target = event.target
      Rails.disableElement(target)

      let vue = this
      const url = window.location.href
      const {error} = this.stripe.confirmSetup({
        elements: this.stripeElements,
        confirmParams: {
          return_url: url,
        }
      }).then( (result) => {
        if(result.error) {
          this.cardErrors = result.error.message
          Rails.enableElement(target)
        }
      })
    },
    handleToken(token) {
      this.stripeToken = token.id
      Vue.nextTick( () => {
        this.$emit('submit-stripe')
        if(this.form) {
          this.form.submit()
        }
      })
    }
  }
})

export default stripeForm
