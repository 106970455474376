import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'

export function setStorageWithExpiry(key, value) {
  const item = {
    value: value,
    date: new Date().toDateString(),
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getStorageWithExpiry(key) {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const today = new Date().toDateString()

  if (today !== item.date) {
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

const adViewMixin = {
  methods: {
    saveAdView(ad, click = false) {
      if(!this.publisherId) {
        return
      }
      const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      let data = {
        publisher_id: this.publisherId,
        uuid: this.uuid,
        apply_click: click
      }
      if(ad) {
        data.advertisement_id = ad.id
      }
      axios({
        method: 'post',
        url: '/public/ad_views.json',
        data: data,
        withCredentials: true,
        headers: {
          'X-CSRF-Token': token
        }
      })
    },
    setUuid() {
      const uuid = getStorageWithExpiry('diversifieds-session')
      if(uuid) {
        this.uuid = uuid
      }
      else {
        this.uuid = uuidv4()
        setStorageWithExpiry('diversifieds-session', this.uuid)
      }
    }
  }
}

export default adViewMixin