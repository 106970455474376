import Vue from 'vue/dist/vue.esm'

export const pageSetup = () => {
  Vue.config.ignoredElements = ['trix-editor', 'trix-toolbar']

  const mainVue = new Vue({
    el: '#app',
    mounted() {
      // focus the first field in the form
      window.setTimeout(() => $(this.el).find('input[autofocus]').focus(), 10)
    },
    data() {
      return {
        showAdDropdown: false
      }
    }
  })

  let table = document.querySelector('table.linked tbody')
  if(table) {
    table.addEventListener('click', event => {
      if(event.target.nodeName.toUpperCase() == 'A') {
        return true
      }
      const tr = event.target.closest('tr')
      const link = tr.querySelector('a')
      const href = link ? link.getAttribute('href') : null
      if(href) {
        tr.className += ' clicked'
        window.location = href
      }
    })
  }

}