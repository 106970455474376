import Vue from 'vue/dist/vue.esm'
import VueCurrencyFilter from 'vue-currency-filter'
import { pageSetup } from 'modules/page_setup'
import Rails from "@rails/ujs"

import vSelect from 'vue-select'
import startCase from 'lodash/startCase'


// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import AdListing from 'pages/ad_listing'
Vue.component('ad-listing', AdListing)
import StripeForm from 'modules/stripe_form'
Vue.component('stripe-form', StripeForm)
import AdForm from 'modules/ad_form'
Vue.component('ad-form', AdForm)

import AdvertiserDropdown from 'modules/advertiser_dropdown'
Vue.component('advertiser-dropdown', AdvertiserDropdown)

Vue.component('v-select', vSelect)

// =========== Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
library.add(faArrowLeft)

Vue.component('font-awesome-icon', FontAwesomeIcon)




Vue.use(VueCurrencyFilter, [
 {
   name: 'currency',
   symbol: '$',
   thousandsSeparator: ',',
   fractionCount: 2,
   fractionSeparator: '.',
   symbolPosition: 'front',
   symbolSpacing: false,
   avoidEmptyDecimals: '',
 }])

 Vue.filter('humanize', function (val) {
   if (!val) return ''
   return startCase(val)
  })


Rails.start()
// Turbolinks.start()
ActiveStorage.start()

document.addEventListener('DOMContentLoaded', pageSetup)
