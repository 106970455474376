import Vue from 'vue/dist/vue.esm'
import Rails from '@rails/ujs'
import { DateTime } from 'luxon'
const adForm = Vue.component('ad-form', {
  props: [
  ],
  mounted() {

    var passedData = this.$el.dataset
    //
    for(var key in passedData) {
      this[key] = passedData[key]
    }
    Vue.nextTick( () => {
      this.pageLoaded = true
require("trix")
require("@rails/actiontext")
// import "trix"
// import "@rails/actiontext"
    })
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      baseRate: null,
      dayPrice: null

    }
  },
  watch: {
  },
  computed: {
    showSubmit() {
      return this.startDate && this.endDate
    },
    adDays() {
      if(this.startDate && this.endDate) {
        return DateTime.fromISO(this.endDate).diff(DateTime.fromISO(this.startDate), 'days').days
      }
      else {
        return 0
      }
    },
    adPrice() {
      const daysOverTwoWeeks = Math.max(this.adDays - 13, 0)
      return daysOverTwoWeeks * (+this.dayPrice) + (+this.baseRate)
    }
  },
  methods: {
  }
})

export default adForm
