<template>
<v-select :options="advertisers" label="name" placeholder="Switch Advertiser" v-model="advertiser" v-bind:clearSearchOnSelect="true" v-bind:closeOnSelect="true"></v-select>

</template>

<script>

export default {
  props: [
    'options'
  ],
  mounted() {
    this.advertisers = JSON.parse(this.options)
  },
  data() {
    return {
      advertisers: [],
      advertiser: null
    }
  },
  watch: {
    advertiser(val) {
      if(val) {
        const path_end = window.location.pathname.split('/').slice(3,9).join('/')
        window.location = `/advertisers/${val.id}/${path_end}`
      }
    }
  },
  methods: {
  }
}

</script>