<template>
<div :id="placementId" />
</template>

<script>

export default {
  mixins: [],
  props: ['kind', 'index'],
  created() {
    this.dimensions = {
      banner: {
        width: 728,
        height: 90,
        id: process.env.AD_BUTLER_BANNER
      },
      mobile: {
        width: 320,
        height: 50,
        id: process.env.AD_BUTLER_MOBILE
      },
      sidebar: {
        width: 300,
        height: 250,
        id: process.env.AD_BUTLER_SIDEBAR
      }
    }
  },
  mounted() {
    var AdButler = window.AdButler || {}
    AdButler.ads = AdButler.ads || []
    const id = this.id
    const width = this.width
    const height = this.height
    this.$nextTick(() => {
        AdButler.ads.push({handler: function(opt){ AdButler.register(process.env.AD_BUTLER_ID, id, [width,height], `placement_${id}_${opt.place}`, opt); }, opt: { place: this.index, keywords: this.abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
    })

  },
  data() {
    return {
    }
  },
  computed: {
    id() {
      return this.dimensions[this.kind].id
    },
    width() {
      return this.dimensions[this.kind].width
    },
    height() {
      return this.dimensions[this.kind].height
    },
    abkw() {
      return window.abkw || ''
    },
    placementId() {
      return `placement_${this.id}_${this.index}`
    }
  },
  methods: {

  }
}

</script>
