<template>
  <li v-if="ad" @click="viewAd" :class="{item_box: true, current: isCurrent}">
    <div class="logo" v-if="advertiser && advertiser.logo"><img :src="advertiser.logo" /></div>
    <h4>{{ ad.title}}</h4>
    <div class="company">
      <strong>{{ advertiser.name }}</strong>
    </div>
    <div class="location">{{ advertiser.city_state }}</div>
    <div class="row align-middle">
      <div class="columns"></div>
      <div class="columns shrink"><a class="button small apply secondary" href="#" target="_blank" @click.prevent="viewAd">More Info</a></div>
    </div>
  </li>
</template>

<script>

import adViewMixin from '../modules/ad_view_mixin'

export default {
  mixins: [adViewMixin],
  props: ['ad', 'publisherId', 'isCurrent'],
  mounted() {
    this.advertiser = this.ad.advertiser
  },
  data() {
    return {
      advertiser: {}
    }
  },
  methods: {
    viewAd() {
      this.$emit('select-ad', this.ad)
    },
    applyClick(e) {
      e.stopPropagation()
      this.saveAdView(this.ad, true)
    }
  }
}

</script>
